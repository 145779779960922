// vendors
import React from "react"
import { css } from "@emotion/core"

import CircleContainer from "../../../components/CircleContainer"
import { colors, fonts, fontSizes } from "../../../styles/variables"
import media, { greaterThen } from "../../../utils/media-query"
import wrapper from "../../../utils/wrapper"
import normalizeRegionNumber from "../../../utils/normalizeRegionNumber"

const CaracteristicsGrid = ({ region }) => (
  <div
    css={css`
      align-items: center;
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: ${70 / fontSizes[0]}rem;

      ${media.greaterThen(1024)} {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);

        margin: 0 0 5em 0;
      }
    `}
  >
    <CircleContainer
      css={{
        backgroundColor: colors.blueyGreen,
        borderColor: colors.blueyGreen,
        textAlign: "center",
      }}
    >
      <p css={{ lineHeight: 20 / 18, marginBottom: "1em" }}>
        Région
        <br />
        administrative{" "}
        <span
          css={{
            fontSize: `${84 / 18}em`,
            display: "block",
            fontWeight: "900",
            lineHeight: 0,
            margin: `${54 / 84}em 0 ${54 / 84}em`,
          }}
        >
          {normalizeRegionNumber(region.regionNumber)}
        </span>{" "}
        du Québec
      </p>
    </CircleContainer>

    <blockquote
      css={css`
        display: flex;
        flex-flow: column;
        justify-content: center;
        font-size: ${24 / fontSizes[0]}em;
        line-height: ${30 / 24};
        font-weight: bold;
        margin: 0;
        padding-top: 2.5em;
        padding-bottom: 2.5em;
        background: linear-gradient(
            to left,
            ${colors.offWhite} 50%,
            transparent 0
          )
          no-repeat bottom;

        ${media.lessThen(1024)} {
          ${wrapper.bolt("padding")}
          margin-left: calc(50% - 50vw);
          margin-right: calc(50% - 50vw);
        }

        ${media.greaterThen(1024)} {
          height: 100%;
          padding: 0 2em 0 1ch;
          background: linear-gradient(
              to left,
              ${colors.offWhite} 65%,
              transparent 0
            )
            no-repeat bottom;
        }

        ${greaterThen(fontSizes.breakpoint)} {
          font-size: ${36 / fontSizes[1]}em;
          line-height: ${44 / 36};
        }

        p {
          margin-bottom: 1em;
        }
      `}
    >
      <p dangerouslySetInnerHTML={{ __html: region.quote.quote }} />

      <footer
        css={css`
          font-size: ${20 / 24}em;
          font-family: ${fonts.accent};
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          ${greaterThen(fontSizes.breakpoint)} {
            font-size: ${24 / 36}em;
          }
        `}
      >
        <p
          css={css`
            margin-bottom: 0 !important;
            :before {
              content: "— ";
            }
          `}
          dangerouslySetInnerHTML={{ __html: region.quote.cite }}
        />
      </footer>
    </blockquote>

    <CircleContainer
      css={{ borderColor: colors.pastelRed, textAlign: "center" }}
    >
      <h3>Spécialités locales</h3>

      <ul
        css={css`
          list-style: none;
          margin: 0 1em;
          padding: 0;
        `}
      >
        {region.localSpeciality.map((speciality) => (
          <li
            css={css`
              display: inline-block;
              &:not(:last-child):after {
                content: ",";
                padding-right: 0.5ch;
              }
            `}
          >
            {speciality}
          </li>
        ))}
      </ul>
    </CircleContainer>

    {region.regionMRC.length > 0 && (
      <div
        css={css`
          ${greaterThen(1024)} {
            padding-left: 1ch;
          }
        `}
      >
        <h3>MRC de la région</h3>

        <ul
          css={css`
            list-style: none;
            margin: 0;
            padding: 0;
          `}
        >
          {region.regionMRC.map(({ name, url }) => (
            <li
              css={css`
                display: inline-block;
                &:not(:last-child):after {
                  content: ",";
                  padding-right: 0.5ch;
                }
              `}
            >
              {name}
            </li>
          ))}
        </ul>
      </div>
    )}
  </div>
)

export default CaracteristicsGrid

// vendors
import React from "react"

const RegionMap = ({ selectedRegion, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 74.58 93.91"
      {...props}
    >
      {/* terres-cries */}
      {selectedRegion === "terres-cries-de-la-baie-james" && (
        <g>
          <polyline
            points="2.21 46.71 5.46 46.71 5.45 48.24 2.62 48.99 2.21 46.71"
            fill="#e86752"
          />
          <polyline
            points="1.74 59.05 2.64 59.87 3.43 58.87 4.85 58.85 4.85 57.34 3.01 57.33 1.74 59.05"
            fill="#e86752"
          />
          <polyline
            points="3.51 55.04 5.42 55.05 5.42 53.89 3.51 53.89 3.57 55"
            fill="#e86752"
          />
          <polyline
            points="3.23 50.46 5.52 50.48 5.53 51.84 3.51 52.23 3.23 50.46"
            fill="#e86752"
          />
          <rect x="11.12" y="55.82" width="1.36" height="1.36" fill="#e86752" />
          <polygon
            points="19.37 61.52 18.06 63.66 18.06 65.04 19.16 65.04 21.32 61.52 19.37 61.52"
            fill="#e86752"
          />
          <rect x="11.12" y="67.43" width="3.57" height="1.25" fill="#e86752" />
          <polygon
            points="0.41 42.97 8.8 43 17.86 42.21 24.31 41.1 27.05 52.58 24.64 54.3 22.5 61.72 18.77 68.02 18.57 71.23 0.55 70.54 0.52 59.98 3.39 56.35 3.54 52 2.43 47.36 0.41 42.97"
            fill="#e86752"
            fill-rule="evenodd"
            opacity="0.25"
          />
        </g>
      )}

      {/* Nunavik */}
      {selectedRegion === "nunavik" && (
        <polygon
          points="47.72 30.64 45.94 26.48 45.88 21.3 43.37 19.6 43.95 16.55 42.13 15.97 41.99 13.65 39.82 9.54 39.39 15.86 35.92 21.04 34.44 21.22 30.65 18.47 27.21 19.92 28.21 17.17 27.27 13.91 26.56 8.52 23.25 7.02 21.86 6.6 19.25 6.82 19.11 5.16 18.97 3.44 17.25 3.32 17.11 3.31 16.05 1.43 15.47 0.4 13.53 2.24 10.93 2.4 6.4 0.71 4.96 2.78 6.57 6.15 5.93 8.54 7.44 14.76 5.74 16.06 6.02 17.87 3.65 20.07 4.16 21.59 6.46 23.18 9.1 26.96 9.57 31.93 7.95 37.29 4.32 41.15 0.46 42.96 0.46 42.97 8.8 43 17.86 42.21 24.31 41.1 38.49 37.46 38.54 37.47 39.06 36.3 42.7 37.28 48.89 36.74 47.72 30.64"
          fill="#e86752"
        />
      )}

      {/* Laurentides */}
      {selectedRegion === "laurentides-secteur-hautes-laurentides" && (
        <polygon
          points="14.94 76.14 14.71 77.11 15.14 78.21 14.64 79.26 13.3 79.61 12.52 80.6 13.6 83.54 12.65 86.17 13.53 86.8 14.37 86.51 15.32 86.93 15.92 90.52 16.89 90.17 20.56 90.86 21.95 90.42 18.32 85.13 16.99 79.81 17.61 77.12 14.94 76.14"
          fill="#e86752"
        />
      )}

      {/* iles-de-la-madeleine */}
      {selectedRegion === "iles-de-la-madeleine" && (
        <g>
          <polygon
            points="53.28 67.33 50.09 66.43 44.76 69.36 46.46 70.95 44.94 72.69 42.72 73.42 41.89 74.13 44.44 75.12 49.05 72.27 52.28 72.51 54.47 69.65 53.28 67.33"
            fill="#e86752"
            opacity="0.25"
          />
          <polygon
            points="55.27 75.23 53.49 77.16 55.44 77.98 57.85 77.28 58.34 75.19 56.63 74.27 55.27 75.23"
            fill="#e86752"
          />
        </g>
      )}

      {/* Gaspesie */}
      {selectedRegion === "gaspesie" && (
        <g>
          <polygon
            points="53.28 67.33 50.09 66.43 44.76 69.36 46.46 70.95 44.94 72.69 42.72 73.42 41.89 74.13 44.44 75.12 49.05 72.27 52.28 72.51 54.47 69.65 53.28 67.33"
            fill="#e86752"
          />
          <polygon
            points="55.27 75.23 53.49 77.16 55.44 77.98 57.85 77.28 58.34 75.19 56.63 74.27 55.27 75.23"
            fill="#e86752"
            opacity="0.25"
          />
        </g>
      )}

      {/* Nord du québec */}
      {selectedRegion === "nord-du-quebec" && (
        <polygon
          points="0.41 42.97 8.8 43 17.86 42.21 24.31 41.1 27.05 52.58 24.64 54.3 22.5 61.72 18.77 68.02 18.57 71.23 0.55 70.54 0.52 59.98 3.39 56.35 3.54 52 2.43 47.36 0.41 42.97"
          fill="#e86752"
          fill-rule="evenodd"
        />
      )}

      {/* Cote-nord */}
      {selectedRegion === "cote-nord" && (
        <polygon
          points="56.27 50.54 53.64 51.34 51.98 47.34 50.49 47.46 50.95 53.09 45.98 51.52 44.05 51.68 42.97 48.05 41.31 49.38 39.83 47.75 40.51 46.52 37.14 40.63 38.55 37.45 24.31 41.1 32.22 75.01 34.13 76.58 34.95 75.45 37.66 71.11 42.68 66.2 44.71 62.45 51.96 59.89 52.7 62.21 55.93 62.95 61.06 62.44 62.73 61.47 61.38 60.33 62.73 58.47 67.71 56.14 70.72 50.78 74.18 47.52 73.65 45.06 56.27 50.54"
          fill="#e86752"
        />
      )}

      {/* Abitibi */}
      {selectedRegion === "abitibi-temiscamingue" && (
        <polygon
          points="15.44 71.04 14.97 76.14 10.07 78.21 9.34 79.38 7.07 80.24 4.71 79.98 4.14 86.59 2.4 86.29 0.4 82.97 0.55 70.54 15.44 71.04"
          fill="#e86752"
        />
      )}

      {/* Outaouais */}
      {selectedRegion === "outaouais" && (
        <polygon
          points="15.92 90.52 15.32 86.93 14.37 86.51 13.53 86.8 12.65 86.17 13.6 83.54 12.52 80.6 13.3 79.61 14.64 79.26 15.14 78.21 14.71 77.11 14.94 76.16 14.94 76.16 10.07 78.21 9.34 79.38 7.07 80.24 4.71 79.98 4.14 86.59 3.84 86.54 3.85 86.67 8.73 87.91 10.35 90.09 13.52 91.44 15.94 90.52 15.92 90.52"
          fill="#e86752"
        />
      )}

      {/* Mauricie */}
      {selectedRegion === "mauricie-et-centre-du-quebec" && (
        <polygon
          points="15.33 71.08 18.51 71.15 23.55 76.77 26.76 77.51 24.66 80.13 25.58 81.47 25.02 82.77 27.25 84.84 24.21 86.87 20.45 82.03 18.96 78.46 18.04 77.28 14.91 76.15 15.33 71.08"
          fill="#e86752"
        />
      )}

      {/* Saguenay */}
      {selectedRegion === "saguenay-lac-saint-jean" && (
        <polygon
          points="32.22 75.01 27 52.61 24.64 54.3 22.5 61.72 18.77 68.02 18.57 71.23 18.48 71.23 23.59 76.83 26.79 77.54 33.95 76.43 32.22 75.01"
          fill="#e86752"
        />
      )}

      {/* Bas Saint-Laurent */}
      {selectedRegion === "bas-saint-laurent" && (
        <polyline
          points="34.02 79.67 35.96 80.54 37.52 77.85 42.72 73.42 44.94 72.69 46.46 70.95 44.65 69.26 41.14 71.16 35.03 78.5 34.02 79.67"
          fill="#e86752"
        />
      )}

      {/* Carte */}
      <path
        d="M5,2.78,6.57,6.15,5.93,8.54l1.51,6.21-1.7,1.31L6,17.87l-2.37,2.2.51,1.52,2.3,1.59L9.1,27l.47,5L8,37.29,4.32,41.15.46,43l1.75,3.75,1.19,5,.06,4.83L.57,59.92l.14,2.34L.4,83l2,3.32,6.47,1.64,1.46,2.16,3.12,1.34,3.44-1.27,3.67.7-1.11,2.65,10.94-.78,2.44-2.15,2.06-4.43-.24-1.83,4.41-5.92,10-6.13,3.23.24,2.19-2.86-1.19-2.32-3.19-.9-9,4.73s-7.07,8.4-7.12,8.51l-2.58,1.41,6.22-10,5-4.91,2-3.75L52,59.89l.74,2.32,3.23.74,5.13-.51,1.67-1-1.35-1.14,1.35-1.86,5-2.33,3-5.36,3.46-3.26-.53-2.46L56.27,50.54l-2.63.8-1.66-4-1.49.12L51,53.09l-5-1.56-1.93.15L43,48.05l-1.66,1.33-1.48-1.63.68-1.23-3.37-5.89,1.92-4.33,3.64,1,6.19-.54-1.17-6.1-1.78-4.16-.06-5.18-2.51-1.7L44,16.55,42.13,16,42,13.65,39.82,9.54l-.43,6.32L35.92,21l-1.48.18-3.79-2.75-3.44,1.45,1-2.75-.93-3.26-.72-5.39L23.25,7,21.86,6.6l-2.61.22-.14-1.66L19,3.44l-1.72-.12h-.14L16.05,1.43l-.58-1L13.53,2.24l-2.6.16L6.4.72Z"
        fill="none"
        stroke="#2bb673"
        stroke-linejoin="round"
        stroke-width="0.8"
      />
    </svg>
  )
}

export default RegionMap

// vendors
import React from "react"
import { css } from "@emotion/core"

const CircleContainer = ({ children, ...props }) => (
  <div
    css={css`
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 6px);
      max-width: 314px;
      max-height: 314px;
      border-radius: 100%;
      border-width: 3px;
      border-style: solid;

      :before {
        padding-bottom: 100%;
        display: block;
        content: "";
        width: 0px;
      }

      > div > * {
        margin-top: 0;
        padding-top: 0;
      }
    `}
    {...props}
  >
    <div>{children}</div>
  </div>
)

export default CircleContainer

// vendors
import React from "react"
import { css } from "@emotion/core"
import VisuallyHidden from "@reach/visually-hidden"
import { greaterThen } from "../../../utils/media-query"
import { fontSizes } from "../../../styles/variables"

const Value = ({ icon, title, value }) => (
  <li
    css={css`
      display: flex;
      flex-flow: column;
      flex-grow: 1;
      justify-content: flex-start;

      margin: 1em 0.75em;
    `}
  >
    <img
      src={icon}
      alt=""
      css={css`
        display: block;
        margin: 0 auto 0.5em;
        height: ${49 / 23}em;
      `}
    />

    <VisuallyHidden>
      <p>{title}:</p>
    </VisuallyHidden>

    <p dangerouslySetInnerHTML={{ __html: value }} />
  </li>
)

const Values = ({ children }) => (
  <ul
    css={css`
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 1rem -0.75em;
      padding: 0;
      font-size: ${23 / fontSizes[0]}em;
      line-height: ${34 / 30};
      font-weight: bold;
      text-align: center;

      ${greaterThen(fontSizes.breakpoint)} {
        font-size: ${30 / fontSizes[1]}em;
      }
    `}
  >
    {children}
  </ul>
)

export default Values

export { Value }

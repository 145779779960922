// vendors
import React from "react"
import wrapper from "../../../utils/wrapper"
import { css } from "@emotion/core"
import { colors, fontSizes, fonts } from "../../../styles/variables"
import styled from "@emotion/styled"
import { useStaticQuery, graphql, Link } from "gatsby"
import normalizeRegionNumber from "../../../utils/normalizeRegionNumber"
import regionLinkGenerator from "../../../utils/region-link-generator"
import mediaQuery from "../../../utils/media-query"
import Button from "../../../components/Button/Button"

const Block = styled.div`
  display: flex;

  :before {
    padding-bottom: 100%;
    content: "";
    display: block;
  }
`

const Code = styled.span`
  margin-bottom: 20px;
  font-size: ${52 / 30}em;
  line-height: 1;
  font-weight: 900;
  display: block;
`

const Title = styled.span``

const RegionLink = ({ to, code, title }) => {
  return (
    <Link
      to={to}
      css={css`
        position: relative;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-decoration: none;
        color: ${colors.darkTeal};
        text-align: center;
        border: 3px solid ${colors.darkTeal};
        font-size: ${30 / fontSizes[1]}em;
        line-height: ${36 / 30};
        font-weight: 600;
        padding: 1em;

        :after {
          position: absolute;
          font-family: ${fonts.accent};
          font-size: ${24 / 30}em;
          font-weight: 700;
          content: "Voir la région  →";
          top: 50%;
          transform: translateY(-50%);
          opacity: 0;
        }

        :hover,
        :focus {
          background-color: ${colors.darkPeach};
          border-color: ${colors.darkPeach};

          :after {
            opacity: 1;
          }

          ${Title} {
            opacity: 0;
          }
        }
      `}
    >
      <Title>
        <Code>{code}</Code>
        {title}
      </Title>
    </Link>
  )
}

const RegionGrid = ({ actual }) => {
  const data = useStaticQuery(graphql`
    query {
      regions: allSanityRegion {
        nodes {
          meta {
            slug {
              current
            }
          }
          title
          regionNumber
        }
      }
    }
  `)

  const regions = data.regions.nodes
    // .filter(({ regionNumber }) => regionNumber !== actual)
    .filter(({ title }) => title !== actual)
    .sort((a, b) => a.regionNumber - b.regionNumber)
    .map((node) => {
      if (!node.meta || !node.meta.slug) return false

      const title = node.title
      const regionNumber = node.regionNumber
      const slug = node.meta.slug.current

      return {
        path: regionLinkGenerator(slug),
        title,
        code: normalizeRegionNumber(regionNumber),
      }
    })
    .filter((node) => node)

  return (
    <div>
      <div
        css={css`
          ${wrapper.bolt()}

          margin-bottom: ${fontSizes[9] / fontSizes[0]}em;

          ${mediaQuery.greaterThen(1024)} {
            display: none;
          }
        `}
      >
        <p
          css={css`
            font-size: ${fontSizes[6] / fontSizes[0]}rem;
            font-weight: 800;
            line-height: ${48 / 44};
            margin-bottom: ${48 / 44}em;
          `}
        >
          Explorez les&nbsp;autres régions
        </p>

        <div>
          <Button tag="link" to="/regions" full secondary>
            Voir les régions
          </Button>
        </div>
      </div>

      <div
        css={css`
          ${wrapper.bolt("padding")}
          background-color: ${colors.blueyGreen};
          display: grid;
          padding-top: 8em;
          padding-bottom: 8em;
          align-items: center;

          ${mediaQuery.lessThen(1024)} {
            display: none;
          }

          ${mediaQuery.greaterThen(1024)} {
            grid-template-columns: repeat(3, 1fr);
          }

          ${mediaQuery.between(1024, 1560)} {
            div:nth-child(2n) {
              > * {
                border-radius: 50%;
              }
            }
          }

          ${mediaQuery.greaterThen(1560)} {
            grid-template-columns: repeat(4, 1fr);

            div:nth-child(2),
            div:nth-child(4),
            div:nth-child(5),
            div:nth-child(7),
            div:nth-child(10),
            div:nth-child(12) {
              > * {
                border-radius: 50%;
              }
            }
          }
        `}
      >
        <div>
          <p
            css={css`
              font-size: ${68 / fontSizes[1]}em;
              line-height: ${70 / 68};
              font-weight: 900;
              margin: 0;
            `}
          >
            Explorez les autres régions
          </p>
        </div>
        {regions.map(({ path, title, code }, index) => (
          <Block>
            <RegionLink to={path} index={index} code={code} title={title} />
          </Block>
        ))}
      </div>
    </div>
  )
}

export default RegionGrid
